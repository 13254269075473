import { axiosApi } from './requests/BaseRequest'
import { Data } from '@/stores/data'
import { ElementTip } from '@/stores/ElementTip'
export class HTTP {
  /**
   *  post 获取二维码
   * @returns
   */
  static getQrCode() {
    return axiosApi.request({
      url: '/userinfo/getLoginQrCode',
      method: 'get'
    })
  }

  /**
   *  post 二维码微信登录，获取用户信息和token
   * @returns
   */
  static wxLoginStatus(key: string) {
    return axiosApi.request({
      url: '/userinfo/loginStatus',
      method: 'GET',
      params: {
        key
      }
    })
  }

  /**
   *  get 获取学段学科
   * @returns
   */
  static getSubject() {
    return axiosApi.request({
      url: '/dict/getSubject',
      method: 'GET'
    })
  }

  /**
   *  get 获取版本
   * @returns
   */
  static getEdition(gradeId: number | string) {
    return axiosApi.request({
      url: '/dict/getEdition/' + gradeId,
      method: 'get'
    })
  }

  /**
   *  get 获取年级
   * @returns
   */
  static getGrade(subjectId: number | string) {
    return axiosApi.request({
      url: '/dict/getGrade/' + subjectId,
      method: 'get'
    })
  }

  /**
   * post 获取册别
   * @param editionId 教材版本id
   * @param gradeId  年级id
   * @returns
   */
  static getVolume(editionId: string) {
    return axiosApi.request({
      url: '/dict/getVolume/' + editionId,
      method: 'GET'
    })
  }

  /**
   *  get 获取教案结构
   * @returns
   */
  static getStructure(resourceType: string) {
    return axiosApi.request({
      url: '/dict/get/structure',
      method: 'GET',
      params: {
        resourceType
      }
    })
  }

  /**
   *  post 获取sessionId
   * @returns
   */
  static getSessionId() {
    return axiosApi.request({
      url: '/myFile/getId',
      method: 'GET'
    })
  }

  /**
   *  post 上传文件列表
   * @returns
   */
  static pdfUpload(formData: any, onUploadProgress: any) {
    return axiosApi.request({
      url: '/myFile/analysis',
      timeout: 10 * 60 * 1000,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: {
        ...formData
      },
      onUploadProgress
    })
  }
  static uploadImage(file: File) {
    const maxSize = 5 * 1024 * 1024
    console.log('>file.size>>', file.size)
    if (file.size > maxSize) {
      ElementTip.warningTip('文件过大')
      return Promise.reject('文件过大')
    }
    const formData = new FormData()
    formData.append('dataKey', '0')
    formData.append('bizType', 'common')
    formData.append('file', file)
    return axiosApi.request({
      url: '/myFile/uploadImage',
      timeout: 30 * 1000,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    })
  }
  /**
   *  post 获取ppt  json
   * @returns
   */
  static pptGeneration(content: string) {
    return axiosApi.request({
      url: '/ai/document/pptGeneration',
      method: 'post',
      timeout: 0,
      data: {
        content
      }
    })
  }

  /**
   *  post 生成教案
   * @returns
   */
  static generationLessonPlan(data: any) {
    return axiosApi.request({
      url: '/ai/document/generation',
      method: 'post',
      data: {
        ...data
      }
    })
  }

  //获取问答的回答
  static getAIAnswer(data: any) {
    return axiosApi.request({
      url: '/ai/question',
      data: {
        ...data
      }
    })
  }

  //获取卡片默认信息
  static getCardData(data: any) {
    return axiosApi.request({
      url: '/ai/card',
      data: {
        ...data
      }
    })
  }

  //获取卡片默认信息
  static getHistoryData(sessionId: string) {
    return axiosApi.request({
      url: '/myFile/getHistory/' + sessionId,
      method: 'get'
    })
  }

  //获取教案信息
  static getBookOne(data: any) {
    return axiosApi.request({
      url: '/t-book/one',
      data: {
        ...data
      }
    })
  }

  //获取图片
  static wenToImage(content: string) {
    return axiosApi.request({
      url: '/myFile/wenToImage',
      id: '/myFile/wenToImage',
      data: {
        content
      }
    })
  }

  /**
   * 保存
   * @param data
   * @returns
   */
  static addFile(data: any) {
    return axiosApi.request({
      url: '/myFile/add',
      data: {
        ...data
      }
    })
  }
  /**
   * 导出
   * @param data
   * @returns
   */
  static downloadFile(data: any) {
    return axiosApi.request({
      url: '/myFile/downloadFile',
      data: {
        ...data
      }
    })
  }

  //获取知识库文件列表
  static getWorkList(data: any) {
    return axiosApi.request({
      url: '/myFile/page',
      data: {
        ...data
      }
    })
  }

  //删除单个文件
  static deleteWorkItem(id: string) {
    return axiosApi.request({
      url: '/myFile/del/' + id,
      method: 'get'
    })
  }
  static getFileById(id: string) {
    return axiosApi.request({
      url: '/myFile/by/' + id,
      method: 'get'
    })
  }

  /**
   *  get 获取用户信息
   * @returns
   */
  static getUserInfo() {
    return axiosApi.request({
      url: '/userinfo/getUserInfo',
      method: 'get'
    })
  }

  /**
   *  get 获取生成设置 名称
   * @returns
   */
  static getSearchData(phaseId: string, subjectId: string, content: string, resourceType: string) {
    return axiosApi.request({
      url: '/ai/search',
      method: 'get',
      params: {
        phaseId,
        subjectId,
        content,
        resourceType
      }
    })
  }

  /**
   *  get 获取图形验证码
   * @returns
   */
  static getLoginQrCodeStrBase64() {
    return axiosApi.request({
      url: '/userinfo/getLoginQrCodeStrBase64',
      method: 'get'
    })
  }

  /**
   *  get 获取短信验证码
   * @returns
   */
  static getLoginQrCodeSMS(data: any) {
    return axiosApi.request({
      url: '/userinfo/getLoginQrCodeSMS',
      method: 'post',
      data: {
        ...data
      }
    })
  }

  /**
   *  post 提交申请表单
   * @returns
   */
  static submitMembership(data: any) {
    return axiosApi.request({
      url: '/userinfo/submitMembership',
      method: 'post',
      data: {
        ...data
      }
    })
  }

  /**
   *  get 获取微信群二维码 个人客服
   * @returns
   */
  static getWXQrCode() {
    return axiosApi.request({
      url: '/userinfo/getQrCode',
      method: 'get'
    })
  }

  /**
   *  get 领取会员资格
   * @returns
   */
  static confirmationMembership() {
    return axiosApi.request({
      url: '/userinfo/confirmationMembership',
      method: 'get'
    })
  }

  /**
   *  post 提交申请表单
   * @returns
   */
  static submitFeedback(data: any) {
    return axiosApi.request({
      url: '/problem-feedback/add',
      method: 'post',
      data: {
        ...data
      }
    })
  }

  /**
   *  post 活动是否开启
   * @returns
   */
  static activityInfo() {
    return axiosApi.request({
      url: '/activity/activityInfo',
      method: "GET",
    })
  }
}
