<script setup lang="ts">
import { ref } from 'vue'
import { Data } from '@/stores/data'
import { ElMessage } from 'element-plus'
import { WarningFilled } from '@element-plus/icons-vue'
import { Utill } from '@/stores/Utill'
import emitter from '@/stores/mitt'
import { HTTP } from '@/network/http'
import DialogItem from '@/components/Application/dialog-components/DialogItem.vue'
const logoutVisible = ref(false)

console.log(Data.userInfo.value)
const loginDialog = ref(false)
const communityCode = ref('')
const customerCode = ref('')

const dialogType = ref('')
const dialogTitle = ref('')

init()

function init() {
  HTTP.getWXQrCode()
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        console.log(res.data.community)
        console.log(res.data.customer)
        communityCode.value = res.data.community
        customerCode.value = res.data.customer
        Data.qrCodeList.value = res.data
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
    })
  HTTP.activityInfo()
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        Data.isActivityOpen.value = res.data != null;
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
    })
}

function logoutClick() {
  logoutVisible.value = false
  ElMessage({
    type: 'success',
    message: '退出登录成功'
  })
  Utill.outLoading()
}

function showLoginDialog() {
  loginDialog.value = true
  console.log('header111')
  dialogType.value = 'login'
  dialogTitle.value = ''
  let dialogData = {
    dialogType: 'login',
    show: true,
    dialogTitle: ''
  }
  console.log(dialogData)
  emitter.emit('show-appdialog', dialogData)
}

function showFeedbackDialog() {
  loginDialog.value = true
  console.log('feedback')
  dialogType.value = 'feedback'
  dialogTitle.value = '意见反馈'
  let dialogData = {
    dialogType: 'feedback',
    show: true,
    dialogTitle: '意见反馈'
  }
  console.log(dialogData)
  emitter.emit('show-appdialog', dialogData)
}

function showActivityDialog() {
  if ( Data.isLogin.value == false ){
    showLoginDialog()
    return
  }
  // let dialogData = {
  //   show: true,
  //   actDialogType: 'main-act',
  //   actDialogTitle: ''
  // }
  // emitter.emit('show-actDialog', dialogData)
  let dialogData = {
    show: true,
    tipDialogType: 'officialAccount',
    tipDialogTitle: "公众号"
  }
  emitter.emit('show-tipDialog', dialogData)
}

function closeDialog(data: string) {
  if (data == 'ppt' || data == 'experience') {
    return
  }
  loginDialog.value = false
  let dialogData = {
    dialogType: dialogType.value,
    show: false,
    dialogTitle: dialogTitle.value
  }
  console.log(dialogData)
  emitter.emit('show-appdialog', dialogData)
}
</script>

<template>
  <div class="herder-ctr" :class="Data.isLogin.value == false ? 'null-header-ctr' : ''">
    <div class="logo-ctr">
      <img src="@/assets/xh.png" alt="Logo" class="logo-img" />
      <div class="title">小鸿助教</div>
    </div>
    <div class="right-ctr">
      <div
        class="act-ctr"
        @click="showActivityDialog"
        v-show="Data.isActivityOpen.value"
      >
        <img class="act-ctr-img" src="@/assets/activity/act-btn-img.png" alt="" />
        <p class="act-title">邀请活动</p>
      </div>
      <div
        class="wx-ctr"
        v-show="Data.isTryOutAuthority.value && Data.isLogin.value"
        @click="showFeedbackDialog"
      >
        <img class="wx-icon" src="@/assets/feedback-icon.png" alt="" />
        <span>意见反馈</span>
      </div>
      <el-popover placement="bottom" :width="144" trigger="hover">
        <template #reference>
          <div class="wx-ctr">
            <img class="wx-icon" src="@/assets/weixinQun-icon.png" alt="" />
            <span>微信群</span>
          </div>
        </template>
        <template #default>
          <div class="qrcode-img-ctr">
            <img class="wx-qrcode-img" :src="communityCode" alt="" />
          </div>
          <div class="wx-tip">
            <p>微信扫一扫</p>
            <p>加入用户交流群</p>
          </div>
        </template>
      </el-popover>
      <el-popover placement="bottom" :width="144" trigger="hover">
        <template #reference>
          <div class="wx-ctr">
            <img class="wx-icon" src="@/assets/customer-icon.png" alt="" />
            <span>个人客服</span>
          </div>
        </template>
        <template #default>
          <div class="qrcode-img-ctr">
            <img class="wx-qrcode-img" :src="customerCode" alt="" />
          </div>
          <div class="wx-tip">
            <p>微信扫一扫</p>
            <p>联系你的专属客服</p>
          </div>
        </template>
      </el-popover>

      <div class="user-ctr" v-if="Data.isLogin.value">
        <div style="height: 25px" v-if="Data.lv.value == '13' && Data.userInfo.value?.endTime">
          <img src="@/assets/vip-icon.png" alt="User Avatar" class="vip-img" />
        </div>
        <div class="user-name">
          {{ Data.userInfo.value?.nickname ? Data.userInfo.value?.nickname : '微信用户' }}
        </div>
        <el-popover placement="bottom" :width="228" trigger="hover">
          <template #reference>
            <div class="user-avatar">
              <img
                :src="
                  Data.userInfo.value?.headimgurl
                    ? Data.userInfo.value?.headimgurl
                    : 'https://static.yipigai.cn/theme/20240722191033_57291.png'
                "
                alt="User Avatar"
                class="user-img"
              />
            </div>
          </template>
          <template #default>
            <div class="user-info-ctr">
              <img
                :src="
                  Data.userInfo.value?.headimgurl
                    ? Data.userInfo.value?.headimgurl
                    : 'https://static.yipigai.cn/theme/20240722191033_57291.png'
                "
                alt="User Avatar"
                class="info-img"
              />
              <div>
                <p class="info-name">
                  {{ Data.userInfo.value?.nickname ? Data.userInfo.value?.nickname : '微信用户' }}
                </p>
                <p class="info-id">
                  用户ID：{{ Data.userInfo.value?.id ? Data.userInfo.value?.id : '' }}
                </p>
              </div>
            </div>
            <div class="vip-info" v-if="Data.lv.value == '13'">
              <p class="vip-title">VIP会员</p>
              <p class="vip-time">
                有效期至：{{ Data.userInfo.value?.endTime ? Data.userInfo.value?.endTime : '' }}
              </p>
            </div>
            <div class="logout-btn" @click="logoutVisible = true">退出登录</div>
          </template>
        </el-popover>
      </div>
      <div class="user-ctr" v-else-if="!Data.isLogin.value">
        <el-button @click="showLoginDialog" class="login-btn" color="#485CEC">登录</el-button>
      </div>
    </div>
  </div>
  <div class="logout-dialog">
    <el-dialog v-model="logoutVisible" title="退出登录" width="380" :show-close="false">
      <span class="logout-icon">
        <el-icon>
          <WarningFilled />
        </el-icon>
      </span>
      <span>确认退出登录吗?</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="logoutVisible = false" class="cancel-btn">取消</el-button>
          <el-button type="primary" @click="logoutClick" class="ok-btn"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <DialogItem
    v-if="loginDialog"
    :dialogVisible="loginDialog"
    @closeDialog="closeDialog"
    :dialogType="dialogType"
    :dialogTitle="dialogTitle"
  >
  </DialogItem>
</template>

<style scoped lang="scss">
.herder-ctr {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #edf1ff 0%, rgba(245, 247, 251, 0) 100%);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  //  border-width: 0px 0px 1px 0px;
  //border-style: solid;
  //border-color: #E1E1E1;
}

.null-header-ctr {
  background: linear-gradient(90deg, #edf1ff 0%, rgba(245, 247, 251, 0.2) 100%) !important;
}

.logo-ctr {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 40px;
  margin-left: 21px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  color: #040a39;
}

.right-ctr {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.user-ctr {
  display: flex;
  align-items: center;
  margin-left: 57px;
}

.wx-ctr {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #485cec;
  margin-left: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.act-ctr {
  margin-left: 46px;
  width: 101px;
  height: 25px;
  background: linear-gradient(102deg, #AC46DF 9%, #3C3FE5 97%);
  border-radius: 46px;
  color: #FFFFFF;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.act-ctr-img {
  position: absolute;
  transition: opacity 0.5s;
}

.act-title{
  margin-left: 27px;
  margin-top: 2px;
}

.wx-ctr:hover {
  cursor: pointer;
}

.qrcode-img-ctr {
  margin-bottom: 12px;
  width: 120px;
  height: 120px;
}

.wx-qrcode-img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  opacity: 1;
  border: 1px solid #eaeaea;
}

.wx-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.wx-tip {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #718096;
}

.user-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #1d2129;
}

.user-avatar {
  overflow: hidden;
  margin-left: 8px;
}

.user-img {
  width: 32px;
  height: 32px;
  border: 1px solid #e5e6eb;
  border-radius: 50%;
}

.vip-img {
  width: 75px;
  height: 24px;
  margin-right: 8px;
}

.user-info-ctr {
  display: flex;
  align-items: center;
  padding: 4px 4px 0;
}

.info-img {
  margin-right: 8px;
  width: 32px;
  height: 32px;
  border: 1px solid #e5e6eb;
  border-radius: 50%;
}

.info-name {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #1d2129;
}

.info-id {
  font-size: 12px;
  font-weight: normal;
  line-height: 22px;
  color: #909399;
}

.vip-info {
  background: url('@/assets/vip-bg.png') no-repeat;
  background-size: cover;
  width: 196px;
  height: 63px;
  margin-top: 10px;
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 7px;
  margin-left: 4px;
}

.vip-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #fc7709;
}

.vip-time {
  font-size: 12px;
  font-weight: normal;
  line-height: 22px;
  color: #909399;
  margin-top: 4px;
}

.logout-btn {
  width: 196px;
  height: 32px;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d2129;
  border-radius: 43px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  margin: 16px 4px 0;
}

.logout-btn:hover {
  background: #eceefd;
  box-sizing: border-box;
  border: 1px solid #a3adf5;
  cursor: pointer;
}

.logout-dialog {
  :deep(.el-dialog) {
    border-radius: 16px;
    box-shadow: 0px 4px 10px 0px rgba(158, 172, 229, 0.3);
    padding: 20px 20px 11px;

    .el-dialog__header {
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      align-items: center;
      font-variation-settings: 'wght' 600;
      color: #040a39;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      /*align-items: center;*/
    }
  }
}

.dialog-footer {
  :deep(.el-button) {
    width: 60px;
    height: 32px;
    border-radius: 92px;
    font-size: 14px;
    font-weight: 500;
  }
}

.cancel-btn {
  background: #eceefd;
  border: none !important;
}

.cancel-btn:hover {
  background: #cacff9;
  border: none !important;
}

.ok-btn {
  background: #485cec;
  margin-left: 8px;
}

.ok-btn:hover {
  background: #3347d6;
}

.logout-icon {
  width: 16px;
  height: 16px;
  margin-right: 9px;
  color: #e6a23c;
  font-size: 16px;
}

.login-btn {
  width: 80px;
  height: 36px;
  border-radius: 32px;
  color: #ffffff;
  background: #485cec;
}
</style>
<style lang="scss">
.el-popover {
  --el-popover-border-radius: 16px !important;
  box-shadow: 0px 4px 10px 0px rgba(158, 172, 229, 0.3) !important;
  border: none !important;
  padding: 12px !important;
  top: 55px !important;
  min-width: 140px !important;
}
</style>
