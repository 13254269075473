<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import XhHeader from '@/components/XhHeader.vue'
import { Data } from '@/stores/data'
import { HTTP } from '@/network/http'
import { useScreenStore, useMainStore, useSnapshotStore } from '@/stores/pptStores'
import { LOCALSTORAGE_KEY_DISCARDED_DB } from '@/configs/storage'
import DialogItem from '@/components/Application/dialog-components/DialogItem.vue'
import emitter from '@/stores/mitt'
import TipDialog from '@/components/Application/dialog-components/TipDialog.vue'
import { Utill } from '@/stores/Utill'
const mainStore = useMainStore()
const { databaseId } = storeToRefs(mainStore)

const tipDialog = ref(false)
const tipDialogType = ref('')
const tipDialogTitle = ref('')

window.addEventListener('beforeunload', handleBeforeUnload)
// 应用注销时向 localStorage 中记录下本次 indexedDB 的数据库ID，用于之后清除数据库
window.addEventListener('unload', () => {
  const discardedDB = localStorage.getItem(LOCALSTORAGE_KEY_DISCARDED_DB)
  const discardedDBList: string[] = discardedDB ? JSON.parse(discardedDB) : []

  discardedDBList.push(databaseId.value)

  const newDiscardedDB = JSON.stringify(discardedDBList)
  localStorage.setItem(LOCALSTORAGE_KEY_DISCARDED_DB, newDiscardedDB)
})
function handleBeforeUnload() {

  let data: any = {}
  data.currFileId = Data.currFileId.value
  data.sessionId = Data.sessionId.value
  data.currShowType = Data.currShowType.value
  data.basicSettingData = Data.basicSettingData.value
  data.workSettingData = Data.workSettingData.value
  sessionStorage.setItem('editorData', JSON.stringify(data))
  console.log('>>>>>ssssssssss>>>>>22555>>', Data.currShowType.value)
}

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
  //   console.log('>>>>>ssssssssss>>>>>>>>')
  //   let data:any={}
  //   data.currFileId=Data.currFileId.value;
  //   data.currShowType=Data.currShowType.value;
  // data.basicSettingData=Data.basicSettingData.value;
  //   localStorage.setItem('editorData',JSON.stringify(data))
})


onMounted(() => {
  // let loadTime=localStorage.getItem('loadTime');
  // 获取当前时间戳
  const currentTime = new Date().getTime()
  console.log(Utill.getCurrentDate())

  // 获取上次保存的时间戳
  const lastTime = localStorage.getItem('lastVisitTime')

  // // 如果是首次访问或者距离上次访问时间超过 24 小时，则保存当前时间戳
  // if (!lastTime || currentTime - parseInt(lastTime) >= 24 * 60 * 60 * 1000) {
  //   localStorage.removeItem('userInfo')
  //   console.log('距离上次访问超过24小时，已更新访问时间。')
  // } else {
  //   console.log('距离上次访问未超过24小时。')
  // }
  localStorage.setItem('lastVisitTime', currentTime.toString())
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  let token = localStorage.getItem('userInfoToken')
  console.log(userInfo)
  // alert(userToken)

  if (userInfo && token) {
    const savedData = JSON.parse(sessionStorage.getItem('editorData'))
    if (savedData) {
      Data.sessionId.value = savedData.sessionId
      Data.currFileId.value = savedData.currFileId
      Data.currShowType.value = savedData.currShowType
      Data.workSettingData.value = savedData.workSettingData
      Data.basicSettingData.value = savedData.basicSettingData
      sessionStorage.removeItem('editorData')
      console.log('>>>>>ssssssssss>>>>>22555557>>', Data.currShowType.value)
    }

    console.log('>已有>>')
    Data.userInfo.value = userInfo
    Data.token.value = token
    Data.isLogin.value = true
    if (Data.token.value) {
      checkRemaining()
    }
  } else {
    Data.isLogin.value = false
  }

})


emitter.on('show-tipDialog', (data: any) => {
  console.log(data)
  tipDialog.value = data.show
  tipDialogType.value = data.tipDialogType
  tipDialogTitle.value = data.tipDialogTitle
  console.log(tipDialog.value)
})

onBeforeUnmount(() => {
  emitter.off('show-tipDialog')
})

function receiveVIP() {
  HTTP.confirmationMembership()
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        checkRemaining()
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)

    })
}
function checkRemaining() {
  return HTTP.getUserInfo()
    .then((res) => {
      console.log(res.data)
      Data.userInfo.value = res.data
      Data.lv.value = res.data.lv
      if (res.data.lv == '13') {
        Data.isTryOutAuthority.value = true
        const currentDate = Utill.getCurrentDate()
        Data.isVipExpiration.value = currentDate > res.data.endTime
      }
      if (res.data.lv == '15') {
        Data.isTryOutAuthority.value = true
        Data.isVipExpiration.value = true
      }
      if (res.data.lv == '13' && res.data.memberConfirmation == 0) {
        tipDialog.value = true
        tipDialogType.value = 'get'
        tipDialogTitle.value = '恭喜您！'
      }
      console.log('更新用户信息')
      console.log(Data.userInfo.value)
      localStorage.setItem('userInfo', JSON.stringify(res.data))
    })
    .catch(() => { })
}

function closeTipDialog(dialogType: string) {
  console.log(dialogType)
  tipDialog.value = false
  if (dialogType == 'get') {
    receiveVIP()
  }
}
</script>

<template>
  <div class="app">
    <!-- <div ref="editor"></div> -->
    <div class="main_container" :class="Data.isLogin.value == false ? 'login-container' : ''">
      <div class="header">
        <XhHeader></XhHeader>
      </div>
      <div class="main">
        <router-view />
      </div>

      <TipDialog v-if="tipDialog" :dialogVisible="tipDialog" :dialogType="tipDialogType" @closeDialog="closeTipDialog"
        :dialogTitle="tipDialogTitle" key="3">
      </TipDialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.header {
  height: 60px;
  //
  //z-index: 1;
  //position: relative;
}

.main_container {
  height: 100%;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  background: url('@/assets/right-bg.jpg') no-repeat;
  background-size: cover;
  -webkit-background-size: cover;

  .main {
    flex: 1;
    // height: calc(100% - 60px);
    width: 100%;
    overflow: hidden;
  }
}

/* 公式超出出现滚动条 */
.katex-display {
  overflow: auto hidden;
}

/* markdown css*/
.default-theme p {
  padding: 0.05rem 0;
}

.md-editor-preview {
  font-size: 0.14rem;
}

.default-theme ul {
  padding-left: 0;
}

.default-theme table thead {
  background: #abcee826;
}

.md-editor-preview-wrapper {
  padding: 0;
}

.md-editor {
  --md-color: #1e1e1e;
}

.login-container {
  // background: url("@/assets/bg.jpg") no-repeat !important;
  height: 100%;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  position: relative;
}
</style>
